import React, {useEffect} from 'react';
import {ConfigProvider, theme} from "antd";
import i18next from "i18next";
import uz_Uz from 'antd/locale/uz_UZ';
import ru_RU from 'antd/locale/ru_RU';
import en_US from 'antd/locale/en_US';


const ThemeProvider = ({
                           dark, children, colorBgContainer = null, setColorBgContainer = (color) => {
    }
                       }) => {

    const {defaultAlgorithm, darkAlgorithm, compactAlgorithm} = theme;

    useEffect(() => {
        setColorBgContainer(dark ? "#141414" : "#ffffff")
    }, [dark])

    return (
        <ConfigProvider
            locale={i18next.language === "uz" ? uz_Uz : i18next.language === "eng" ? en_US : ru_RU}
            theme={{
                algorithm: dark ? [darkAlgorithm, compactAlgorithm] : [defaultAlgorithm, compactAlgorithm],
                token: {
                    colorBgContainer: colorBgContainer || dark ? "#141414" : "#ffffff",
                }
            }}
        >
            {children}
        </ConfigProvider>
    );
};

export default ThemeProvider;