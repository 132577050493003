import React from 'react';
import classNames from "classnames";
import logo from '../assets/img/logo.png';
import {Typography} from "antd";

const Logo = ({height = 51, width = 58, collapsed = false, sidebar = false}) => {
    return (
        <div className={"d-flex justify-context-center align-items-center"}>
            <img className={classNames({
                "me-2": !collapsed
            })} src={logo} alt="Logo" height={height} width={width}/>
            {!collapsed && <span className={classNames({
                "fs-3": sidebar,
                "fs-5": !sidebar,
            },'m-0 fw-bolder')} style={{color: "#2c7be5"}}>Hippo</span>}
        </div>
    );
};

export default Logo;