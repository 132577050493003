import {useState} from "react";

const useCollapsedMode = () => {

    const [collapsed, setCollapsed] = useState(localStorage.getItem('collapsed') === "close");

    const changeCollapsed = () => {
        setCollapsed(!collapsed)
        localStorage.setItem('collapsed', collapsed ? "open" : "close")
    }

    return {
        collapsed,
        changeCollapsed
    };
};

export default useCollapsedMode;