import React from 'react';
import ThemeProvider from "../../component/ThemeProvider";
import {Result} from "antd";

const Status404 = ({dark}) => {
    return (
        <ThemeProvider dark={dark}>
            <Result status={"404"} title={"404"} subTitle={"Not found"}/>
        </ThemeProvider>
    );
};

export default Status404;