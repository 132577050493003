import React, {createContext, useContext, useState} from 'react';
import {isJSON} from "../component/component";
import {Base64} from "js-base64";

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({children}) => {
    const [userData, setUserData] = useState(localStorage.getItem("authority") && isJSON(Base64.decode(localStorage.getItem("authority"))) ? JSON.parse(Base64.decode(localStorage.getItem("authority"))) : {});

    const updateUserData = (data = null) => {
        if (data) {
            setUserData(data)
        } else {
            const authority = localStorage.getItem("authority")
            setUserData(_ => authority && isJSON(Base64.decode(authority)) ? JSON.parse(Base64.decode(authority)) : {})
        }
    };

    return (
        <AuthContext.Provider value={{userData, updateUserData}}>
            {children}
        </AuthContext.Provider>
    );
};
