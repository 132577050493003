import React, {useImperativeHandle, useRef, useState} from 'react';
import PropTypes from "prop-types";
import {Button, Flex, Modal, Space, Typography} from "antd";
import Draggable from 'react-draggable';
import {PlusOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const MyModal = React.forwardRef(({
                                      modalTitle,
                                      title,
                                      onCancel,
                                      afterClose,
                                      maskClosable,
                                      children,
                                      width,
                                      excel,
                                      button,
                                      style
                                  }, ref) => {

    const draggableRef = useRef();
    const {t} = useTranslation()

    const [open, setOpen] = useState(false);

    const [disabled, setDisabled] = useState(true);
    const [bounds, setBounds] = useState({left: 0, top: 0, bottom: 0, right: 0});

    useImperativeHandle(ref, () => ({
        closeModal() {
            setOpen(false)
        },
        openModal() {
            setOpen(true)
        }
    }))

    const onStart = (event, uiData) => {
        const {clientWidth, clientHeight} = window?.document?.documentElement;
        const targetRect = draggableRef?.current?.getBoundingClientRect();
        setBounds({
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y),
            }
        );
    };

    return (
        <>
            <Flex justify={"space-between"} style={{margin: "20px 0"}}>
                <Flex align={"center"}>
                    <Typography.Text style={{fontWeight: 700, fontSize: 15}}>{title}</Typography.Text>
                </Flex>
                <React.Fragment>
                    <Space size={23}>
                        {excel}
                        {button &&
                            <Button ghost icon={<PlusOutlined/>} type="primary" onClick={() => setOpen(true)}>
                                {t('add')}
                            </Button>
                        }
                    </Space>

                </React.Fragment>

            </Flex>
            <Modal
                width={width || 600}
                style={style}
                title={
                    <div
                        className={"w-100"}
                        style={{cursor: 'move'}}
                        onMouseOver={() => {
                            if (disabled) setDisabled(false)
                        }}
                        onMouseOut={() => setDisabled(true)}
                    >{modalTitle}</div>
                }
                modalRender={modal => (
                    <Draggable
                        nodeRef={draggableRef}
                        disabled={disabled}
                        bounds={bounds}
                        onStart={(event, uiData) => onStart(event, uiData)}
                    >
                        <div ref={draggableRef}>{modal}</div>
                    </Draggable>
                )}
                afterClose={() => {
                    setBounds({left: 0, top: 0, bottom: 0, right: 0});
                    afterClose()
                }}
                maskClosable={maskClosable}
                open={open}
                onCancel={() => {
                    setOpen(false)
                    onCancel()
                }}
                footer={false}
            >
                {children}
            </Modal>
        </>

    );
});

MyModal.propTypes = {
    title: PropTypes.string.isRequired,
    onCancel: PropTypes.func,
    afterClose: PropTypes.func,
    maskClosable: PropTypes.bool,
    children: PropTypes.node,
    button: PropTypes.bool,
    style: PropTypes.object,
}

MyModal.defaultProps = {
    title: '',
    onCancel: () => null,
    afterClose: () => null,
    maskClosable: true,
    children: null,
    button: true,
    style: {},
}

export default MyModal;