import React, {useEffect} from 'react';
import {Button, Card, Form, Input, Layout, Typography} from "antd";
import useSWRMutation from "swr/mutation";
import {afterRequest, postRequest, triggerAxios} from "../../api/config";
import {useNavigate} from "react-router-dom";
import {Base64} from "js-base64";
import {login, profile_data} from "../../api/api";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import ThemeProvider from "../../component/ThemeProvider";
import Logo from "../../component/Logo";
import {MyCleave} from "../../component/MyCleave";
import useDarkMode from "../../config/useDarkMode";
import {useAuth} from "../../config/AuthProvider";

const {Title} = Typography

const Login = ({dark}) => {

    const {t} = useTranslation()
    const navigate = useNavigate();
    const [form] = Form.useForm()
    const {trigger, isMutating: isLoading} = useSWRMutation(login, postRequest)
    const {trigger: getProfileTrigger, isMutating} = useSWRMutation(profile_data, triggerAxios)

    const {setDarkMode} = useDarkMode()
    const {updateUserData} = useAuth()

    useEffect(() => {
        localStorage.clear();
    }, [])

    const onFinish = (values) => {
        let username = "998" + values.username.replace(/\s/g, '')
        afterRequest(trigger, {data: {...values, username}}, () => {
            afterRequest(getProfileTrigger, {method: "GET", after: true}, (res) => {
                localStorage.setItem("authority", Base64.encode(JSON.stringify(res)))
                setDarkMode(dark)
                updateUserData(res)
                navigate("/")
            })
        })
    }

    useEffect(() => {
        const errorFields = form.getFieldsError()
            .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);
        form.validateFields(errorFields).then();
    }, [i18n.language]);

    const style = {
        display: "flex",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
        background: dark ? `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='%23000'/%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23FFFFFF' fill-opacity='0.12'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")` : `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.12'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
    }

    return (
        <ThemeProvider
            dark={dark}>
            <Layout
                style={style}
            >
                <Card style={{
                    padding: "48px",
                    boxShadow: `0 15px 50px rgba(${dark ? '255, 255, 255, 0.2' : '0, 0, 0, 0.2'})`
                }}>
                    <Logo/>
                    <Form
                        form={form}
                        layout={"vertical"}
                        name="basic"
                        onFinish={onFinish}
                        style={{width: 250}}
                    >
                        <Title className={"text-center"} level={2}>{t('loginTitle')}</Title>
                        <Form.Item
                            style={{marginBottom: "1.5rem"}}
                            name="username"
                            shouldUpdate
                            rules={[{required: false, message: t('required', {input: t('username')})}]}
                        >
                            <div className={"d-flex"}>
                                <Input
                                    style={{width: '20%', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                    defaultValue="+998"
                                    disabled
                                />
                                <MyCleave
                                    dark={dark.toString()}
                                    placeholder={"-- --- -- --"}
                                    options={{
                                        delimiters: [' ', ' ', ' ', ' '],
                                        blocks: [2, 3, 2, 2],
                                        numericOnly: true,
                                    }}
                                    style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                />
                            </div>
                        </Form.Item>
                        <Form.Item
                            style={{marginBottom: "1.5rem"}}
                            name="password"
                            rules={[{required: false, message: t('required', {input: t('password')})}]}>
                            <Input.Password
                                size={"large"}
                                placeholder={t('password')}
                                required/>
                        </Form.Item>
                        <Form.Item style={{marginTop: 25}}>
                            <Button block={true} size={"large"} type="primary" htmlType="submit"
                                    loading={isLoading || isMutating}>
                                {t('login')}
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Layout>
        </ThemeProvider>
    )
};

export default Login;