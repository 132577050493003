import {useState} from "react";

const useDarkMode = () => {

    const [dark, setDark] = useState(localStorage.getItem('dark') === 'light')

    const toggleDark = () => {
        setDark(!dark)
        localStorage.setItem('dark', dark ? 'dark' : 'light')
    }

    const setDarkMode = (value) => {
        setDark(value)
        localStorage.setItem('dark', value ? 'light' : 'dark')
    }

    return {
        dark,
        toggleDark,
        setDarkMode
    };
};

export default useDarkMode;