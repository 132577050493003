import React from "react";
import {CustomRouter} from "./CustomRouter";
import AppComponent from "./AppComponent";
import {AuthProvider} from "./config/AuthProvider";
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/uz';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';

dayjs.extend(updateLocale);

dayjs.updateLocale('uz', {
    weekStart: 1,
});

dayjs.updateLocale('ru', {
    weekStart: 1,
});

dayjs.updateLocale('en', {
    weekStart: 1,
});

export const App = () => {
    return <AuthProvider>
        <CustomRouter>
            <AppComponent/>
        </CustomRouter>
    </AuthProvider>
}


export default App;