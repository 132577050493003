import styled from "styled-components";
import Cleave from "cleave.js/react";
import React from "react";

const StyledCleave = styled(Cleave)`
    width: 100%;
    padding: 5.5px 11px;
    border: 1px solid ${({dark}) => JSON.parse(dark) ? '#434343' : '#d9d9d9'};
    border-radius: 8px;
    outline: none;
    font-size: 14px;
    line-height: 1.5715;
    color: ${({dark}) => JSON.parse(dark) ? 'rgba(255, 255, 255, 0.85)' : 'rgba(0, 0, 0, 0.85)'};
    background-color: ${({dark}) => JSON.parse(dark) ? '#141414' : '#fff'};
    transition: all 0.3s;
    box-sizing: border-box;

    &:hover {
        border-color: ${({dark}) => JSON.parse(dark) ? '#40a9ff' : '#40a9ff'};
    }

    &:focus {
        border-color: ${({dark}) => JSON.parse(dark) ? '#40a9ff' : '#40a9ff'};
        box-shadow: 0 0 0 2px ${({dark}) => JSON.parse(dark) ? 'rgba(24, 144, 255, 0.2)' : 'rgba(24, 144, 255, 0.2)'};
    }

    &.ant-input {
        padding: 5.5px 11px;
    }

    &::placeholder {
        color: ${({dark}) => JSON.parse(dark) ? '#bfbfbf' : '#bfbfbf'};
    }

    &.ant-input-disabled {
        background-color: ${({dark}) => JSON.parse(dark) ? '#303030' : '#f5f5f5'};
        color: ${({dark}) => dark ? 'rgba(255, 255, 255, 0.25)' : 'rgba(0, 0, 0, 0.25)'};
        cursor: not-allowed;
    }
`;

export const MyCleave = (props) => {
    return (
        <StyledCleave
            {...props}
            className="ant-input"
        />
    );
};