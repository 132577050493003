import React from 'react';
import useDarkMode from './config/useDarkMode';
import Router from './Router';
import {MoonOutlined, SettingOutlined, SunOutlined} from '@ant-design/icons';
import {routeHistory} from './api/historyRouter';
import {initReactI18next} from 'react-i18next';
import {FloatButton, message, notification} from 'antd';
import {SWRConfig} from 'swr';
import i18next from 'i18next';
import i18n from 'i18next';
import {eng} from './lang/eng';
import {uz} from './lang/uz';
import {ru} from './lang/ru';
import ThemeProvider from "./component/ThemeProvider";
import {useNavigate} from "react-router-dom";

i18n.use(initReactI18next).init({
    resources: {
        uz: {translation: uz},
        ru: {translation: ru},
        eng: {translation: eng},
    },
    lng: localStorage.getItem('lang') ?? 'ru',
    fallbackLng: 'ru',
    interpolation: {escapeValue: false},
})

export default function AppComponent() {

    const {dark, toggleDark} = useDarkMode()

    const changeLang = (lang) => {
        i18next.changeLanguage(lang).then()
        localStorage.setItem("lang", lang)
    }

    const navigate = useNavigate()

    const getMessageContent = (errorData) => {
        if (errorData?.roaming_error) return errorData?.roaming_error
        if (errorData?.income_act_accepted) return i18next.language === "ru" ? "Уже принято" : i18next.language === "eng" ? "Has already been taken" : "Allaqachon qabul qilingan"
        if (errorData?.income_act_not_matched) return i18next.language === "ru" ? "Акт еще не был сопоставлен" : i18next.language === "eng" ? "The deed has not yet been matched" : "Akt hali taqqoslanmagan"
    }

    const val = {
        dedupingInterval: 10000, errorRetryCount: 6, errorRetryInterval: 10000, onError: (error, key) => {
            console.log(error)

            notification?.open({
                pauseOnHover: true,
                message: error?.response?.data?.message || error?.response?.data?.error || error?.message,
                description: getMessageContent(error?.response?.data),
                // key: 'updatable',
                type: 'error',
            })
            switch (error?.response?.status) {
                case 401:
                    navigate("/login")
                    break;
                // case 403:
                //     navigate("/403")
                //     break;
                // case 404:
                //     navigate("/404")
                //     break;
                // case 500:
                //     navigate("/500")
                //     break;
            }
        }
    }

    return (
        <SWRConfig value={val}>
            <ThemeProvider dark={dark}>
                <Router dark={dark}/>
                <FloatButton.Group trigger={'click'} style={{
                    right: 24,
                }} icon={<SettingOutlined spin/>}>
                    <FloatButton icon={dark ? <SunOutlined style={{color: "#FAAD14"}}/> :
                        <MoonOutlined style={{color: "#1677FF"}}/>} onClick={() => toggleDark()}/>
                    <FloatButton description={"Uz"} onClick={() => changeLang("uz")}/>
                    <FloatButton description={"Ру"} onClick={() => changeLang("ru")}/>
                    <FloatButton description={"Eng"} onClick={() => changeLang("eng")}/>
                </FloatButton.Group>
            </ThemeProvider>
        </SWRConfig>
    );
}
