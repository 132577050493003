import React, {useLayoutEffect, useState} from "react";
import {routeHistory} from "./api/historyRouter";
import {HashRouter as Router} from "react-router-dom";

export const CustomRouter = ({children}) => {
    const [state, setState] = useState({
        action: routeHistory.action,
        location: routeHistory.location,
    });

    useLayoutEffect(() => routeHistory.listen(setState), []);

    return (
        <Router
            location={state.location}
            navigationType={state.action}
            navigator={routeHistory}
        >
            {children}
        </Router>
    );
};